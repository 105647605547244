import Api from "./Api";
import qs from "qs";

export default {
  async get({ partitionIds = [] } = {}) {
    return Api().get("organization/GetBuildings", {
      params: { partitionIds },
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    });
  },
  async show(id) {
    return Api().get("organization/GetBuilding", { params: { id } });
  },
  async create(building) {
    return Api().post("organization/CreateBuilding", building);
  },
  async update(building) {
    return Api().put("organization/UpdateBuilding", building);
  },
  async delete(id) {
    return Api().delete("organization/DeleteBuilding", { params: { id } });
  },
};
