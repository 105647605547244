<template>
  <div
    class="block__content d-flex flex-column flex-grow-1"
    style="min-height: 180px"
  >
    <v-form>
      <v-container>
        <v-row>
          <v-col>
            <base-panel headerTogether headerHidden>
              <span class="subtitle">Исходные чек-листы</span>
              <select-check-lists
                @input="loadQuestions($event)"
                :selected-list="selectedCheckLists"
              >
                <template #activator="scope">
                  <m-select
                    :value="selectedCheckLists"
                    :item-value="(e) => e"
                    label="Чек-листы"
                    item-text="Name"
                    multiple
                    chips
                    :clearable="false"
                    v-on="scope.on"
                    v-bind="scope.attrs"
                    @change="change"
                  >
                  </m-select>
                </template>
              </select-check-lists>
            </base-panel>
            <base-panel headerTogether headerHidden>
              <div class="d-flex justify-space-between mb-3 align-center">
                <span class="subtitle">Выбранные чек-листы:</span>
                <v-btn
                  color="primary"
                  small
                  @click="moveQuestions"
                  :disabled="!selectedCheckListQuestions.length"
                >
                  Перенести
                </v-btn>
              </div>
              <v-progress-linear
                :active="loading"
                indeterminate
                height="2"
              ></v-progress-linear>
              <div
                style="
                  width: 100%;
                  max-height: 400px;
                  overflow-y: auto;
                  overscroll-behavior-y: contain;
                "
                v-if="selectedCheckLists.length"
              >
                <v-treeview
                  :items.sync="selectedCheckLists"
                  :value.sync="selectedCheckListQuestions"
                  item-key="Id"
                  item-text="Name"
                  item-children="Children"
                  class="block__content"
                  dense
                  selectable
                  return-object
                  expand-icon="$ToolArrowDown"
                  :loading="loading"
                  @input="selectedCheckListQuestions = $event"
                >
                  <template v-slot:label="{ item }">
                    <span
                      :style="`font-size: 14px; ${
                        isRequired(item) ? 'font-weight: bold' : ''
                      }`"
                    >
                      {{
                        item.Name
                          ? item.Name
                          : item.Content
                          ? `${item.Num} ${item.Content} `
                          : ""
                      }}
                    </span>
                  </template>
                </v-treeview>
              </div>
            </base-panel>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import _ from "lodash";

import CheckListService from "@/services/CheckListService";

import DataHelper from "@/utils/DataHelper";

import BasePanel from "@/layouts/BasePanel.vue";
import mSelect from "@/components/base/inputs/mSelect";
import SelectCheckLists from "./SelectCheckLists.vue";

export default {
  name: "InitialCheckLists",
  components: {
    SelectCheckLists,
    mSelect,
    BasePanel,
  },
  props: {
    valid: Boolean,
    newCheckList: { type: Object, default: () => {} },
  },
  data() {
    return {
      selectedCheckLists: [],
      selectedCheckListQuestions: [],
      loading: false,
    };
  },
  watch: {
    selectedCheckLists(val) {
      if (val.length < 1) {
        this.selectedCheckListQuestions = [];
      }
    },
    selectedCheckListQuestions(val) {
      this.$emit("selected", val);
    },
  },
  methods: {
    async loadQuestions(val) {
      this.loading = true;
      try {
        const res = [];
        const { data: checkLists } = await CheckListService.getByIds(
          val.map((el) => el.Id)
        );
        for (const checkList of checkLists) {
          for (const questions in checkList.Questions) {
            if (questions.Children?.length !== 0) {
              DataHelper.treeAddParentId(questions.Children, questions.Id);
            }
          }

          // Добавить всем вопросам ид чек-листа
          DataHelper.treeListTraverse(checkList.Questions, (item) => {
            item.CheckList = checkList.Id;
          });
          checkList.Children = checkList.Questions;
          res.push(checkList);
        }
        if (this.newCheckList.Questions.length > 0) {
          await this.change(res);
        } else {
          this.selectedCheckLists = res;
        }
      } catch (e) {
        console.debug(e);
      } finally {
        this.loading = false;
      }
    },
    moveQuestions() {
      if (this.selectedCheckListQuestions.length < 1) return;
      this.$set(this.newCheckList, "Questions", []);

      // Копия выбранных вопросов с сортировкой по номеру в порядке возрастания
      let selectedQuestions = _.cloneDeep(
        this.selectedCheckListQuestions
      ).toSorted((a, b) =>
        a.Num.localeCompare(b.Num, undefined, {
          numeric: true,
        })
      );
      const checkLists = _.cloneDeep(this.selectedCheckLists);

      // Формирование вопросов для нового чек-листа в соответствии с порядком вопросов в чек-листах
      const sortedQuestionsByCheckLists = [];
      for (const checkListId of checkLists.map((el) => el.Id)) {
        sortedQuestionsByCheckLists.push(
          ...selectedQuestions.filter((el) => el.CheckList === checkListId)
        );
      }
      selectedQuestions = sortedQuestionsByCheckLists;

      const newQuestions = [];

      for (const child of selectedQuestions) {
        DataHelper.mergeTwoTreeList(
          newQuestions,
          DataHelper.treeGetAllParents(
            checkLists.find((el) => el.Id === child.CheckList).Children,
            child,
            "Id"
          )
        );
      }
      DataHelper.treeListGenerateNumeration(newQuestions, "IntNum");
      DataHelper.treeListGenerateNumerationText(newQuestions, "Num");
      this.$set(this.newCheckList, "Questions", newQuestions);
    },
    isRequired(question) {
      if (!question.ParentId)
        return DataHelper.treeListTraverse(question, (item) => {
          if (item.IsRequired) {
            return true;
          }
        });
      else if (question.IsRequired) return true;
    },
    async change(val) {
      const tmp = _.xor(this.selectedCheckLists, val);
      const changeItem = tmp.length ? tmp[0] : null;
      if (changeItem && this.selectedCheckLists.find((e) => e === changeItem)) {
        if (this.newCheckList.Questions) {
          const arrDel = [];
          DataHelper.treeListTraverse(
            this.newCheckList.Questions,
            (item, _, parent) => {
              if (item.CheckList === changeItem.Id) {
                if (parent) {
                  arrDel.push([item, parent.Children]);
                } else {
                  arrDel.push([item, this.newCheckList.Questions]);
                }
              }
            }
          );
          if (
            arrDel.length &&
            (await this.$confirm(
              "Вопросы чек-листа используются. Количество вопросов: " +
                arrDel.length +
                ". Очистить вопросы?"
            ))
          ) {
            for (const elem of arrDel) {
              elem[1].splice(elem[1].indexOf(elem[0]), 1);
            }
          } else {
            if (arrDel.length) {
              val.push(changeItem);
            }
          }
        }
      }
      this.selectedCheckLists = val;
    },
  },
};
</script>
