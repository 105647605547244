<template>
  <base-detail-view
    title="Создание чек-листа на основе имеющихся"
    :isUnsaved="isUnsaved && valid && !!dataSource.Questions.length"
    @onSave="save"
  >
    <v-row class="d-flex mt-3">
      <v-col>
        <initial-check-lists
          :newCheckList="dataSource"
          @selected="
            ($event) => {
              selectedQuestions = $event;
            }
          "
        />
      </v-col>
      <v-col>
        <created-check-list
          :newCheckList="dataSource"
          :valid="valid"
          :indicators="AuditIndicators"
          @validate="valid = $event"
          @input="dataSource.Questions = $event"
        />
      </v-col>
    </v-row>
  </base-detail-view>
</template>

<script>
import { mapActions } from "vuex";

import CheckListService from "@/services/CheckListService";

import CreateAndEditMixin from "@/mixins/CreateAndEditMixin";
import UnsavedMixin from "@/mixins/UnsavedMixin";

import DataHelper from "@/utils/DataHelper";

import { defaultCheckListCopy } from "@/data/defaultObjects";

import BaseDetailView from "@/layouts/BaseDetailView.vue";

import InitialCheckLists from "@/components/CheckList/clone/InitialCheckLists.vue";
import CreatedCheckList from "@/components/CheckList/clone/CreatedCheckList.vue";

export default {
  name: "view-CheckListClone",
  components: {
    BaseDetailView,
    InitialCheckLists,
    CreatedCheckList,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: () => {
    return {
      AuditIndicators: [],
      // API
      apiService: CheckListService,
      getDefaultObject: defaultCheckListCopy,
      pageEditName: "CheckListEdit",

      dataSource: defaultCheckListCopy(),
      onlyCreate: true,
      valid: false,

      selectedQuestions: [],
    };
  },
  async created() {
    this.AuditIndicators = await this.LOAD_AUDIT_INDICATORS();
    this.init();
  },
  methods: {
    ...mapActions(["LOAD_AUDIT_INDICATORS"]),
    async save() {
      const unaddedQuestions = this.selectedQuestions.reduce((acc, item) => {
        if (!DataHelper.findTreeById(this.dataSource.Questions, item.Id)) {
          acc.push(item);
        }
        return acc;
      }, []);
      if (
        unaddedQuestions.length &&
        !(await this.$confirm(
          `Есть выбранные вопросы, которые не были перенесены в новый чек-лист. Продолжить? `
        ))
      )
        return;

      DataHelper.treeListGenerateNumeration(this.dataSource.Questions, "Num");
      this.baseSave();
    },
  },
};
</script>
